body,
html,
#root {
	cursor: default;
	display: flex;
	flex-direction: column;
	height: 100%;
	margin: 0px;
	width: 100%;
}
